export default [
  {
    code: `en`,
    title: `English`,
    url: `https://en.unionsleden.com`,
  },
  {
    code: `sv`,
    title: `Svenska / Norska`,
    url: `https://www.unionsleden.com`,
  },
]
