import styled, { css } from "styled-components"

const border = css`1px solid ${({ theme }) => theme.colors.creamLight}`

const tableStyles = css`
  width: 100%;
  word-break: break-word;
  border-collapse: collapse;

  thead {
    tr {
      border-bottom: ${border};
      border-width: 2px;
    }
  }

  tbody,
  tfoot {
    tr {
      &:not(:last-child) {
        border-bottom: ${border};
      }
    }
  }

  td,
  th {
    padding: 0.5em;
    vertical-align: top;
    text-align: left;

    &:not(:last-child) {
      border-right: ${border};
    }
  }

  th {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};
  }
`

const Table = styled.table`
  ${tableStyles}
`

export { Table, tableStyles }
