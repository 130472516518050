import mq from "./mq"
import fonts from "./fonts"
import colors from "./colors"
import zindex from "./zindex"
import easings from "./easings"
import animations from "./animations"
import grid from "./grid"
import helpers from "./helpers"
import map from "./components/map"
import current from "./components/current"
import infobox from "./components/infobox"
import infoblocks from "./components/infoblocks"
import searchResult from "./components/search-result"
import globalNotice from "./components/global-notice"
import cookiesConsent from "./components/cookies-consent"

export default {
  mq,
  fonts,
  colors,
  zindex,
  easings,
  animations,
  grid,
  helpers,
  components: {
    map,
    current,
    infobox,
    infoblocks,
    searchResult,
    globalNotice,
    cookiesConsent,
  },
}
