import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { rem } from "polished"
import Cookies from "universal-cookie"
import { Modal } from "@outdoormap/gatsby-plugin-brand-sites"

const cookies = new Cookies()

const COOKIE_NAME = `language-modal-closed`

const DATA = {
  en: {
    link: `https://www.unionsleden.com/`,
    text: `Unionsleden finns också på svenska!`,
    textSmall: `* Du kan också byta språk när som helst med hjälp av språkmenyn högst upp`,
    btnRedirectText: `Unionsleden på svenska`,
    btnContinueText: `Fortsätt på engelska`,
  },
  sv: {
    link: `https://en.unionsleden.com/`,
    text: `Unionsleden is also available in English!`,
    textSmall: `* You can also switch language at any time using the language dropdown at the top`,
    btnRedirectText: `Unionsleden in English`,
    btnContinueText: `Continue in Swedish`,
  },
}[process.env.GATSBY_LANG]

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  padding: ${rem(40)};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: ${rem(8)};
`

const Text = styled.h2`
  font-size: ${rem(28)};
  text-align: center;
  text-transform: uppercase;
`

const TextSmaller = styled.p`
  font-size: ${rem(19)};
  margin-top: ${rem(10)};
`

const Button = styled.button`
  font-size: ${rem(28)};
  border: 2px solid ${({ theme }) => theme.colors.white};
  padding: ${rem(14)} ${rem(20)};
  margin: ${rem(42)} ${rem(18)} ${rem(8)} ${rem(18)};
  border-radius: ${rem(8)};

  &:hover {
    color: ${({ theme }) => theme.colors.green};
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const LanguageModal = () => {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!cookies.get(COOKIE_NAME) && navigator && navigator.language) {
      const { language } = navigator

      if (
        process.env.GATSBY_LANG === `sv` &&
        (language === `en` || language.split(`-`)[0] === `en`)
      ) {
        setModalOpen(true)
        return
      }

      if (
        process.env.GATSBY_LANG === `en` &&
        (language === `sv` || language.split(`-`)[0] === `sv`)
      ) {
        setModalOpen(true)
        return
      }
    }
  }, [])

  const modalRequestClose = () => {
    setModalOpen(!modalOpen)
    cookies.set(COOKIE_NAME, `true`, { path: `/`, maxAge: 3600 * 24 * 30 * 12 })
  }

  const handleRedirect = () => {
    window.location = DATA.link
  }

  return (
    <Modal isOpen={modalOpen} onRequestClose={modalRequestClose}>
      <Container>
        <Inner>
          <Text>{DATA.text}</Text>

          <TextSmaller>{DATA.textSmall}</TextSmaller>

          <Button type="button" onClick={handleRedirect}>
            {DATA.btnRedirectText}
          </Button>

          <Button type="button" onClick={modalRequestClose}>
            {DATA.btnContinueText}
          </Button>
        </Inner>
      </Container>
    </Modal>
  )
}

export default LanguageModal
