import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import languages from "../../config/languages"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"

const Container = styled.nav`
  a,
  button {
    &:hover {
      color: ${({ theme }) => theme.colors.gold};
    }

    &:active {
      opacity: 0.6;
    }
  }
`

const Sub = styled.ul`
  width: ${rem(240)};
  padding: 1.5em;
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  text-align: right;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 ${rem(4)} ${rem(10)} rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  animation: 0.2s ${({ theme }) => theme.easings.default};
  animation-name: ${({ theme }) => theme.animations.slideInY(-10)},
    ${({ theme }) => theme.animations.fadeIn};

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    position: static;
    margin-top: 0.5em;
    padding: 0.75em;
    text-align: left;
    box-shadow: 0 ${rem(2)} ${rem(6)} rgba(0, 0, 0, 0.2);
  }

  li {
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
`

const Base = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }

  > li {
    position: relative;

    &:not(:last-child) {
      margin-right: 1em;
    }

    &:hover,
    &:focus-within {
      > a,
      > button {
        color: ${({ theme }) => theme.colors.gold};
      }

      ${Sub} {
        display: block;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}

      padding: 0.5em 1em;
      display: block;
      font-size: ${rem(15)};

      @media ${({ theme }) => theme.mq.largeDown} {
        padding: 0.2em 0;
      }

      svg {
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.4em;
        display: inline-block;
      }
    }
  }
`

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

const SecondaryNav = () => {
  // const { pages, config } = useStaticQuery(graphql`
  //   query {
  //     pages: allPagesJson(filter: { id: { in: ["11476"] } }) {
  //       nodes {
  //         title
  //         url: path
  //       }
  //     }

  //     config: configJson {
  //       header {
  //         secondaryNav: secondary_nav {
  //           title
  //           url
  //         }
  //       }
  //     }
  //   }
  // `)

  const langCurrent = languages.find((i) => i.code === process.env.GATSBY_LANG)
  const langSub = []
  languages.forEach(
    (lang) => lang.code !== langCurrent.code && langSub.push(lang)
  )

  const items = [
    // ...pages.nodes,
    // ...config.header.secondaryNav,
    { title: langCurrent.title, sub: langSub },
  ]

  return (
    <Container>
      <Base>
        {items.map((base, i) => (
          <li key={i}>
            <ButtonLink to={base.url}>
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.sub && base.sub.length > 0 && <ChevronDownSvg />}
            </ButtonLink>

            {base.sub && base.sub.length > 0 && (
              <Sub>
                {base.sub.map((sub, j) => (
                  <li key={j}>
                    <Link to={sub.url}>{sub.title}</Link>
                  </li>
                ))}
              </Sub>
            )}
          </li>
        ))}
      </Base>
    </Container>
  )
}

export default SecondaryNav
