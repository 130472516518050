import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import TypeIconSvg from "../type-icon-svg"
import { Heading4 } from "../styled/heading"
import { ParagraphSmall } from "../styled/paragraph"

const Image = styled.figure`
  width: 100%;
  height: 12em;
  margin-bottom: 1em;
  position: relative;
  overflow: hidden;
  border-radius: ${rem(5)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.colors.gold};
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  > .-icon {
    position: absolute;
    z-index: 2;
    top: 0.5em;
    right: 0.5em;
    transform: scale(0.8);
    cursor: help;
  }
`

const Location = styled(ParagraphSmall)`
  margin-top: 0.3em;
`

const Container = styled.article`
  width: 100%;

  a {
    &:hover {
      ${Image} {
        opacity: 0.8;
      }

      ${Heading4} {
        color: ${({ theme }) => theme.colors.gold};
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }

  ${Heading4} {
    width: 80%;
  }
`

const SiteCard = ({
  data: { theType, theTypeData, name, path, image, location },
  hideTypeIcon,
  ...props
}) => {
  return (
    <Container {...props}>
      <Link to={path}>
        <Image>
          {image && <GatsbyImage image={image} alt={name} />}

          {!hideTypeIcon && (
            <HexagonIcon
              iconSvg={<TypeIconSvg name={theType} />}
              className="-icon"
              aria-hidden="true"
              title={theTypeData.title}
              bgColor={theTypeData.color}
            />
          )}
        </Image>

        {name && (
          <Heading4 as="h3" dangerouslySetInnerHTML={{ __html: name }} />
        )}

        {location && (
          <Location dangerouslySetInnerHTML={{ __html: location }} />
        )}
      </Link>
    </Container>
  )
}

export default SiteCard
