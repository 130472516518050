import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading2, Heading4 } from "../styled/heading"
import { ReactComponent as SvgArrowThinRight } from "../../assets/images/icons/arrow-thin-right.svg"

const Info = styled.div`
  width: 80%;
  position: relative;
  z-index: 3;

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    width: 100%;
  }
`

const Image = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Date = styled.time`
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.cream};
`

const ReadMore = styled.div`
  margin-top: ${rem(10)};
  color: ${({ theme }) => theme.colors.brown};

  svg {
    width: 1.4em;
    height: 1.4em;
    margin-left: 0.5em;
  }
`

const Container = styled.article`
  width: 100%;
  min-height: 15em;
  display: flex;

  > a {
    width: 100%;
    min-height: 100%;
    padding: ${rem(25)};
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.gold};
    border-radius: ${rem(5)};
    box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.2);
    color: ${({ theme }) => theme.colors.white};

    @media ${({ theme }) => theme.mq.smallDown} {
      padding: ${rem(15)};
    }

    ${({ $major }) =>
      $major &&
      css`
        height: 20em;
        padding: ${rem(50)};

        @media ${({ theme }) => theme.mq.mediumDown} {
          padding: ${rem(25)};
        }

        @media ${({ theme }) => theme.mq.smallDown} {
          padding: ${rem(15)};
        }

        ${ReadMore} {
          margin-top: ${rem(20)};
        }
      `}

    &:hover {
      &::after {
        opacity: 0.5;
      }

      ${ReadMore} {
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.7;
      }
    }

    &:active {
      &::after {
        transition: none;
        opacity: 0;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.6) 60%,
        rgba(0, 0, 0, 0.6) 100%
      );
      transition: opacity 0.3s ${({ theme }) => theme.easings.default};
    }
  }
`

const ArticleHighlightCard = ({
  data: { title, path, image, date },
  titleTag = `h2`,
  major,
  ...props
}) => {
  const Title = major ? Heading2 : Heading4

  return (
    <Container $major={major} {...props}>
      <Link to={path}>
        <Info>
          {date && (
            <Date datetime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}

          {title && (
            <Title as={titleTag} dangerouslySetInnerHTML={{ __html: title }} />
          )}

          <ReadMore>
            Läs mer
            <SvgArrowThinRight aria-hidden="true" />
          </ReadMore>
        </Info>

        {image && (
          <Image>
            <GatsbyImage image={image} alt={title} />
          </Image>
        )}
      </Link>
    </Container>
  )
}

export default ArticleHighlightCard
