import { rem } from "polished"

const searchResult = {
  br: rem(8),
  backgroundColor: ({ theme }) => theme.colors.cream,
  labelColor: ({ theme }) => theme.colors.white,
  labelBackgroundColor: ({ theme }) => theme.colors.olive,
  labelBr: rem(5),
}

export default searchResult
