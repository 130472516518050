import React from "react"
import { Infobox as InfoboxComponent } from "@outdoormap/gatsby-plugin-brand-sites"

import Styled from "../styled"
import { Anchor } from "../styled/anchor"
import { Label4 } from "../styled/label"
import { ReactComponent as SvgSheet } from "../../assets/images/icons/sheet.svg"

const Infobox = ({ data }) => {
  return (
    <InfoboxComponent
      data={data}
      Anchor={Anchor}
      Title={Label4}
      Styled={Styled}
      DocumentSvg={SvgSheet}
    />
  )
}

export default Infobox
