let colors = {
  white: `#fff`,
  black: `#29292b`,
  metallic: `#43484c`,
  cream: `#efece8`,
  creamLight: `#f4f2ef`,
  gold: `#896c4c`,
  brown: `#bb8b6f`,
  purple: `#735967`,
  blue: `#416d88`,
  olive: `#797a62`,
  green: `#41885c`,
  red: `#884141`,
  naturkartanMapBg: `#f3f3ef`,
}

colors = {
  ...colors,
  // by function:
  imageBackground: colors.black,
}

export default colors
