import React, { useState, useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { intersection } from "lodash"
import { Meta, Map } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import TypeHero from "../components/type-hero"
// import SitesFilter from "../components/sites-filter"
import SitesGrid from "../components/sites-grid"
import { typesCategoriesPreselected } from "../config/type"

const collectCategories = (data) => {
  const categories = {}
  data.nodes.forEach(
    (n) => n.mainCategory && (categories[n.mainCategory.id] = n.mainCategory)
  )
  return Object.values(categories)
}

const collectTags = (data) => {
  const tags = {}
  data.nodes.forEach((n) => n.tags.forEach((t) => (tags[t.id] = t)))
  return Object.values(tags)
}

const filterSites = (sites, selectedCategories, selectedTags) => {
  if (!selectedCategories.length && !selectedTags.length) return sites
  return sites.filter((site) => {
    let include = false
    if (selectedCategories.length && site.mainCategory) {
      include = selectedCategories.includes(site.mainCategory.id)
      if (!include) {
        include = !!site.categories.find((c) =>
          selectedCategories.includes(c.id)
        )
      }
    }
    if (selectedTags.length && !include) {
      include = !!selectedTags.find((id) => site.tags.find((t) => t.id == id))
    }
    return include
  })
}

const TypeTemplate = ({ data: { config, sites } }) => {
  const type = config.types[0]
  const configCategoriesPreselected = typesCategoriesPreselected[type.name]
  const categories = useMemo(() => collectCategories(sites), [sites])
  const tags = useMemo(() => collectTags(sites), [sites])
  const getCategoriesIds = () => categories.map((c) => c.id)
  const getTagsIds = () => tags.map((t) => t.id)
  const preselectedCategories = configCategoriesPreselected.length
    ? intersection(typesCategoriesPreselected[type.name], getCategoriesIds())
    : getCategoriesIds()
  const preselectedTags = configCategoriesPreselected.length ? [] : getTagsIds()
  const [selectedCategories, setSelectedCategories] = useState(
    preselectedCategories
  ) // eslint-disable-line prettier/prettier
  const [selectedTags, setSelectedTags] = useState(preselectedTags)

  const sitesFiltered = useMemo(
    () => filterSites(sites.nodes, selectedCategories, selectedTags),
    [sites.nodes, selectedCategories, selectedTags]
  )

  const mapQuery = [`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`]
  if (!preselectedCategories.includes(`31`)) mapQuery.push(`category=31`)
  selectedCategories.forEach((c) => mapQuery.push(`category=${c}`))

  // const mapQueryChange = (query) => {
  //   const newCategories = query
  //     .map((q) =>
  //       q.type == `category` && getCategoriesIds().includes(q.value)
  //         ? q.value
  //         : null
  //     )
  //     .filter(Boolean)

  //   setSelectedCategories(newCategories)
  // }

  useEffect(() => {
    if (
      sites.nodes.length &&
      !selectedCategories.length &&
      !selectedTags.length
    ) {
      setSelectedCategories(getCategoriesIds())
      setSelectedTags(getTagsIds())
    }
  }, [selectedCategories, selectedTags])

  return (
    <Layout>
      <TypeHero type={type}>
        <Map
          // onQueryChange={mapQueryChange}
          query={mapQuery.join(`&`)}
          menu="fullscreen"
          menuSort="we"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
        />
      </TypeHero>

      <Wrapper>
        {/* <SitesFilter
          categories={categories}
          tags={tags}
          selectedCategories={selectedCategories}
          selectedTags={selectedTags}
          onSelectCategories={setSelectedCategories}
          onSelectTags={setSelectedTags}
        /> */}

        <SitesGrid data={sitesFiltered} type={type} />
      </Wrapper>
    </Layout>
  )
}

export default TypeTemplate

export const Head = ({ data: { config } }) => {
  const type = config.types[0]

  return <Meta data={{ title: type.title, description: type.tagline }} />
}

export const pageQuery = graphql`
  query ($type: String!, $categories: [String]!) {
    config: configJson {
      types(name: $type) {
        name
        slug
        color
        title
        tagline
        description
      }
    }

    sites: allSitesJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      # sort: { name: ASC }
      sort: { marker_point: { lng: ASC } }
    ) {
      nodes {
        ...SitesCardFragment

        categories {
          id: nk_id
        }

        tags(type: "locality") {
          id
          name
        }
      }
    }
  }
`

const Wrapper = styled.div`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
    margin-bottom: ${rem(30)};
  }

  > * + * {
    margin-top: ${rem(20)};
  }
`
