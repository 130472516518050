import { ReactComponent as SvgHouse } from "../../assets/images/icons/house.svg"
import { ReactComponent as SvgPicture } from "../../assets/images/icons/picture.svg"
import { ReactComponent as SvgKnifeFork } from "../../assets/images/icons/knife-fork.svg"
import { ReactComponent as SvgCycle } from "../../assets/images/icons/cycle.svg"
import { ReactComponent as SvgPackage } from "../../assets/images/icons/package.svg"
import { ReactComponent as SvgService } from "../../assets/images/icons/service.svg"
import { ReactComponent as SvgKarta } from "../../assets/images/icons/map-location.svg"

const icons = {
  stages: SvgCycle,
  stays: SvgHouse,
  seeDo: SvgPicture,
  eatDrink: SvgKnifeFork,
  packages: SvgPackage,
  service: SvgService,
  karta: SvgKarta,
}

const TypeIconSvg = ({ name, ...props }) => {
  if (!name) return null

  return icons[name](props)
}

export default TypeIconSvg
