const typesCategories = {
  stages: [`31`], // biking
  stays: [`55`, `56`], // hostel, camping
  eatDrink: [`54`], // cafe
  service: [`72`, `73`, `119`], // service, shop, bikeservice
  packages: [`117`], // package
  seeDo: [], // everything else
}

const typesCategoriesIncluded = typesCategories

const typesCategoriesPreselected = {
  ...typesCategories,
  seeDo: [`87`, `14`], // attraction, to-do
}

module.exports = {
  typesCategories,
  typesCategoriesIncluded,
  typesCategoriesPreselected,
}
