import styled, { css } from "styled-components"

const anchorStyles = css`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    color: ${({ theme }) => theme.colors.gold};
  }

  &:active {
    opacity: 0.6;
  }
`

const Anchor = styled.a`
  ${anchorStyles}
`

export { Anchor, anchorStyles }
