import styled, { css } from "styled-components"

const warningModifier = css`
  &.--warning {
    color: ${({ theme }) => theme.colors.red};
  }
`

const heading1Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}

  font-size: 2.25em; /* 36 */
  word-break: break-word;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 1.5em; /* 24 */
  }

  ${warningModifier}
`

const heading2Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}

  font-size: 1.5em; /* 24 */
  word-break: break-word;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 1.25em; /* 20 */
  }

  ${warningModifier}
`

const heading3Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}

  font-size: 1.25em; /* 20 */
  word-break: break-word;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 1.125em; /* 18 */
  }

  ${warningModifier}
`

const heading4Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}

  font-size: 1.125em; /* 18 */
  word-break: break-word;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 1em;
  }

  ${warningModifier}
`

// const heading5Styles = css``

// const heading6Styles = css``

const Heading1 = styled.h1`
  ${heading1Styles}
`

const Heading2 = styled.h2`
  ${heading2Styles}
`

const Heading3 = styled.h3`
  ${heading3Styles}
`

const Heading4 = styled.h4`
  ${heading4Styles}
`

// const Heading5 = styled.h5`
//   ${heading5Styles}
// `

// const Heading6 = styled.h6`
//   ${heading6Styles}
// `

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  // Heading5,
  // Heading6,
  heading1Styles,
  heading2Styles,
  heading3Styles,
  heading4Styles,
  // heading5Styles,
  // heading6Styles,
}
